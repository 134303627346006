.app-table {
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;

  th,
  td {
    font-family: var(--default-font-family);
    text-align: left;
    height: 30px;
    padding: 10px;
  }

  th {
    font-weight: var(--font-bold);
  }

  td {
    &.app-table-no-data {
      text-align: center;
    }
  }
}
