.draggable-item {
  width: 100%;
  height: 30px;
  background: var(--white);
  border-radius: 5px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: grab;
  padding-left: 28px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;

  &.draggable-item-generated {
    height: 80px;
  }

  &.draggable-item-dimmed {
    opacity: 0.25;
  }

  svg {
    position: absolute;
    left: 6px;
    height: 15px;
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }
}

.file-hierarchy-toolbar {
  display: flex;

  .ant-btn {
    height: 26px;
    font-size: 14px;
    font-weight: 400;
    color: var(--black-3);
    background-color: var(--white);
    border-color: var(--white-4);
    margin-right: 6px;
    margin-top: 10px;
    padding: 0 8px;
    position: relative;
  }
}

.file-hierarchy-root {
  list-style: none;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  padding: 20px 10px 20px 15px;

  ul {
    list-style: none;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
  }

  li {
    line-height: 25px;

    .file-content-wrapper {
      display: flex;
      padding: 0;

      &.selected {
        background: #d9d9d9;
      }

      .file-text {
        display: flex;
        align-items: center;

        & > img {
          margin-right: 2px;
          width: 14px;
          height: auto;
        }

        input {
          display: none;
        }
      }

      .expand-icon {
        margin-right: 5px;
        background: #eee;
        font-size: 10px;
        padding: 1px;
        height: min-content;
        line-height: normal;
        margin-top: 6px;
      }

      .expand-icon-block {
        margin-right: 5px;
        width: 12px;
      }

      .folder-icons {
        margin-left: 1px;

        span {
          margin-left: 7px;
        }

        .node-id {
          margin-left: 7px;
        }
      }
    }
  }
}

.file-hierarchy-dragging-source {
  opacity: 0.3;
}

.file-hierarchy-drop-target {
  background-color: #cecece;
}

.node-edit-modal {
  .ant-modal-content {
    .ant-modal-close {
      width: 25px;
      height: 25px;
      background: var(--failed-color);
      color: var(--white);
      border-radius: 50%;
      top: 30px;
      inset-inline-end: 30px;
    }

    .ant-modal-title {
      margin: 0 auto;
      margin-bottom: 27px;
      margin-top: 20px;

      h2 {
        font-family: var(--default-font-family);
        font-size: 32px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
    }

    .ant-modal-footer {
      .ant-btn {
        width: 150px;
      }
    }
  }
}
