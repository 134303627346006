body {
  font-family: var(--default-font-family);
  font-weight: var(--font-normal);
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  background: var(--white);
  color: var(--black);
  margin: 0;
  position: relative;
  height: 100%;

  .app-page-header {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .ant-page-header-heading {
      .ant-page-header-heading-title {
        font-size: 25px;
      }
    }
  }

  .app-section-title {
    font-family: var(--default-font-family);
    font-weight: var(--font-bold);
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }

  .text-green {
    color: var(--success-color);
  }

  .text-red {
    color: var(--failed-color);
  }

  .action-icons {
    .action-icon {
      color: var(--primary-color);
      cursor: pointer;
      width: 20px;

      &:hover,
      &:focus {
        color: var(--primary-color-hover);
      }
    }

    .disabled-action-icon {
      color: var(--primary-color);
      cursor: not-allowed;
      width: 20px;
      opacity: 0.5;
    }
  }

  @media (max-width: 575px) {
    .ant-row {
      display: block;
    }
  }

  .ant-select-dropdown {
    .rc-virtual-list {
      .rc-virtual-list-holder {
        overflow-y: auto !important;
        overflow-anchor: auto !important;

        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #aeaeae;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #868585;
        }
      }
    }
  }
}
