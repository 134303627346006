// root variables
:root {
  // colors
  --white: #ffffff;
  --white-0: #f0f0f0;
  --white-1: #f1f1f1;
  --white-2: #e2e2e2;
  --white-4: #c4c4c4;
  --white-5: #c5c5c5;
  --white-6: #c6c6c6;
  --white-7: #d1d1d1;

  --black: #000000;
  --black-1: #111111;
  --black-2: #222222;
  --black-3: #333333;
  --black-4: #484644;
  --black-5: #525252;
  --black-6: #6a6a6a;
  --black-7: #7a7a7a;

  --gray: #a4a3a3;
  --blue: #025888;
  --yellow: #ffa800;

  // theme colors
  --primary-color: #ed3e24;
  --primary-color-hover: #fa5e43;
  --secondary-color: #dedede;
  --secondary-color-hover: #ecebeb;

  --success-color: #00ba13;
  --failed-color: #ed3f25;
  --pending-color: #ef8f00;
  --warning-color: #ffd027;
  --alert-color: #eb5757;

  // fonts
  --default-font-family: "Inter", sans-serif;
  --secondary-font-family: "Roboto", sans-serif;
  --default-font-size: "16px";
  --default-line-height: "25px";
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-bold: 700;
}
