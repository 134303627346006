.key-files-page {
  &__title {
    font-family: var(--default-font-family);
    font-weight: var(--font-light) !important;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 24px !important;
  }

  &__content {
    padding: 0;
    list-style: none;

    li {
      padding-top: 11px;
      padding-bottom: 10px;
      border-top: 0.5px solid var(--black);
    }

    &__title {
      font-family: var(--default-font-family);
      font-weight: var(--font-bold) !important;
      font-size: 13px !important;
      line-height: 16px !important;

      img {
        width: 25px;
        height: 25px;
        margin-right: 21px;
      }
    }

    &__documents {
      padding: 5px 45px;

      table {
        width: 45%;

        td {
          font-size: 13px;
          line-height: 16px;
          padding: 6px 0;

          &.td-file-name {
            span {
              display: none;
            }
          }

          &.td-date,
          &.td-download {
            width: 100px;
          }
        }

        .btn-download {
          width: 103px;
          height: 22px;
          border-radius: 30px;
          font-family: var(--default-font-family);
          font-size: 8px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.25em;
        }

        @media (max-width: 991px) {
          width: 60%;
        }

        @media (max-width: 767px) {
          width: 85%;
        }

        @media (max-width: 575px) {
          width: 100%;

          td {
            &.td-file-name {
              span {
                display: block;
              }
            }

            &.td-date {
              display: none;
            }

            &.td-download {
              width: 90px;

              .btn-download {
                width: 100%;
              }
            }
          }
        }
      }

      .no-data {
        font-family: var(--default-font-family);
        font-style: italic;
        font-size: 13px;
        line-height: 16px;
        padding-bottom: 12px;
      }
    }
  }
}
