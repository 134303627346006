.ant-btn {
  border-color: var(--black-4);
  color: var(--black-4);
  background: var(--white);

  &:hover,
  &:focus {
    color: inherit;
    border-color: var(--black);
    color: var(--black);
    background: var(--secondary-color-hover);
  }

  &.ant-btn-default {
    border-color: var(--black-4);
    color: var(--black-4);
    background: var(--white);

    &:hover,
    &:focus {
      color: inherit;
      border-color: var(--black) !important;
      color: var(--black) !important;
      background: var(--secondary-color-hover) !important;
    }
  }

  &.ant-btn-link {
    color: var(--primary-color);
    border: transparent;
    background: transparent;

    &:hover,
    &:focus {
      color: var(--primary-color-hover);
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      color: var(--primary-color);
      border: transparent;
      background: transparent;
      opacity: 0.5;
    }
  }

  &.ant-btn-text {
    color: var(--black);
    border: transparent;
    background: transparent;

    &:hover,
    &:focus {
      border-color: var(--secondary-color-hover);
      background: var(--secondary-color-hover);
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      color: var(--black);
      border: transparent;
      background: transparent;
      opacity: 0.5;
    }
  }

  &.ant-btn-primary {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: var(--white);

    &:hover,
    &:focus {
      color: var(--white);
      border-color: var(--primary-color-hover) !important;
      background: var(--primary-color-hover) !important;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      color: var(--white);
      border-color: var(--primary-color);
      background: var(--primary-color);
      opacity: 0.5;
    }
  }

  &.ant-btn-dashed {
    background: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color);

    &:hover,
    &:focus {
      border-color: var(--primary-color-hover);
      color: var(--primary-color-hover);
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      background: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
      opacity: 0.5;
    }
  }
}
