.not-found-page {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .ant-result-extra {
    display: flex;
    justify-content: center;
  }

  .ant-btn {
    width: 130px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    &:hover {
      color: white;
    }
  }
}
