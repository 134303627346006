.team-roadmap-section {
  .team-roadmap-steps {
    .ant-steps-item {
      .ant-steps-item-container {
        position: relative;

        .ant-steps-item-icon {
          position: absolute;

          .ant-steps-icon {
            img {
              width: 82px;
              height: 82px;
              object-fit: cover;
              opacity: 0.5;
            }
          }
        }

        .ant-steps-item-content {
          width: 82px;

          .ant-steps-item-title {
            position: unset;
            padding-top: 82px;
            font-family: var(--default-font-family);
            font-weight: var(--font-normal);
            font-size: 11px;
            text-align: center;
            width: 100%;
            padding-inline-end: 0;

            $arrow-color: #e74c3c;
            $arrow-size: 1.2em;
            $arrow-border: 1px;
            $half-arrow: calc($arrow-size / 2);

            @media (min-width: 576px) {
              &::after {
                content: "";
                background: slategray;
                position: absolute;
                top: 41px;
                left: 105px;
                right: 5px;
                margin-top: -1px;
                margin-right: 1px;
                transition: all 0.3s ease;
                width: auto;
              }

              &::before {
                content: "";
                display: block;
                position: absolute;
                top: 42px;
                right: 6px;
                width: 10px;
                height: 10px;
                transform-origin: 50% 50%;
                transform: translate(0, -$half-arrow) rotate(45deg);
                box-shadow: inset -#{$arrow-border} $arrow-border 0 0 slategray;
                border-radius: 0 $arrow-size * 0.15 0 0;
              }
            }

            @media (max-width: 767px) {
              &::after {
                display: none;
              }

              &::before {
                right: 2px;
              }
            }
          }
        }

        @media (max-width: 575px) {
          display: flex;
          margin-bottom: 15px;

          .ant-steps-item-tail {
            &::after {
              display: none;
            }
          }

          .ant-steps-item-icon {
            position: relative;
            width: 82px;
            height: 82px;

            .ant-steps-icon {
              width: 82px;
              height: 82px;
              display: inline-block;
            }
          }

          .ant-steps-item-content {
            display: flex;
            width: 100%;
            align-items: center;

            .ant-steps-item-title {
              padding: 0;
              text-align: left;
            }
          }
        }
      }

      &:last-child {
        .ant-steps-item-container {
          .ant-steps-item-content {
            .ant-steps-item-title {
              &::before,
              &::after {
                display: none;
              }
            }
          }
        }
      }

      &.ant-steps-item-finish {
        .ant-steps-item-container {
          .ant-steps-item-icon {
            .ant-steps-icon {
              img {
                opacity: 1;
              }
            }
          }
        }
      }

      @media (max-width: 575px) {
        display: flex;
        justify-content: center;
      }

      @media (max-width: 375px) {
        justify-content: flex-start;
      }
    }
  }

  .team-roadmap-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (max-width: 991px) {
      margin-top: 20px;
    }

    em {
      font-family: var(--default-font-family);
      font-weight: var(--font-normal);
      font-size: 11px;
      line-height: 13px;
      text-align: center;
    }

    .ant-btn {
      font-family: var(--default-font-family);
      font-weight: var(--font-normal);
      margin-top: 11px;
      width: 220px;
      max-width: 90%;
      height: 49px;
      border-radius: 30px;
      font-size: 11px;
      line-height: 49px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.25em;
      border-color: var(--primary-color);
      color: var(--primary-color);
      padding: 0;

      &:hover {
        border-color: var(--primary-color);
        color: var(--white);
        background: var(--primary-color);
      }

      @media (max-width: 375px) {
        width: 100%;
      }
    }
  }
}
