@import "../../assets/styles/variables";

.typography--variant-h1 {
  font-size: 38px;
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.typography--variant-h2 {
  font-size: 34px;
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.typography--variant-h3 {
  font-size: 30px;
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.typography--variant-h4 {
  font-size: 26px;
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.typography--variant-h5 {
  font-size: 18px;
  line-height: 27px;
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.typography--variant-h6 {
  font-size: 16px;
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.typography--variant-p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

// .typography--color-primary {
//   color: $theme-orange;
// }

// .typography--color-secondary {
//   color: $theme-blue;
// }

// .typography--color-default {
//   color: $theme-black;
// }

// .typography--color-error {
//   color: $red;
// }
