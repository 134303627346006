.loading-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;

  .loading-text {
    font-size: 25px;
    margin-top: 15px;
  }
}