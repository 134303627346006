.loading-wrapper {
  height: calc(100vh - 200px);
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;

  h5 {
    font-size: 25px;
    margin-bottom: 0;

    .ant-spin {
      display: block;
      margin-bottom: 10px;
      font-size: 30px;
    }
  }

  .loading-message {
    margin-top: 10px;
  }
}
