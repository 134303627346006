.teams-sharepoint-overview-section {
  margin-top: 65px;

  .app-section-title {
    margin-bottom: 18px;
  }

  .teams-sharepoint-overview-box {
    background: var(--black-4);
    color: var(--white);

    * {
      color: var(--white);
      text-align: center;
    }

    &__title {
      font-family: var(--secondary-font-family);
      font-weight: var(--font-light);
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.25em;
      color: var(--white);
      margin: 0;
      margin-top: 18px;
      margin-bottom: 22px;

      span {
        display: block;

        @media (max-width: 991px) {
          display: inline-block;
          padding-left: 8px;
        }
      }

      &.match-height {
        height: 50px;

        @media (max-width: 991px) {
          height: 34px;
        }

        @media (max-width: 767px) {
          height: 45px;
        }

        @media (max-width: 576px) {
          height: auto;
        }
      }
    }

    &__value {
      font-family: var(--default-font-family);
      font-weight: var(--font-bold);
      font-size: 36px;
      line-height: 44px;
      margin: 0;
      margin-top: 0 !important;
    }

    &__text {
      font-family: var(--secondary-font-family);
      font-weight: var(--font-light);
      font-size: 14px;
      line-height: 17px;
      margin: 0;
    }

    &__files-summary {
      margin-bottom: 15px;
      padding-left: 10px;

      &__item {
        display: flex;

        .teams-sharepoint-overview-box__value {
          width: 155px;

          &.text-faded {
            font-weight: var(--font-medium);
            color: var(--gray);
          }
        }

        .teams-sharepoint-overview-box__text {
          width: calc(100% - 155px);
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          text-align: initial;
          margin: 0;
          padding-left: 10px;
          padding-bottom: 8px;

          &.text-faded {
            color: var(--gray);
          }
        }
      }
    }

    .ant-row:not(.teams-sharepoint-overview-box__files-summary) {
      > .ant-col {
        border-left: 1px solid var(--white);
        padding: 20px;

        @media (max-width: 991px) {
          &:nth-child(3) {
            border-right: 1px solid var(--white);
          }

          &:nth-child(4) {
            border-top: 1px solid var(--white);
            border-right: 1px solid var(--white);
          }
        }

        @media (max-width: 576px) {
          border-right: 1px solid var(--white);
          border-top: 1px solid var(--white);

          &:first-child {
            border-top: none;
          }
        }
      }
    }
  }
}
