@import url("../../assets/styles/_variables.scss");

.login-page {
  overflow-y: auto;
  background: var(--black-2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;

  .login-box {
    width: 450px;
    max-width: 100%;
    background: var(--white-0);
    border-radius: 5px;
    padding: 40px;

    .login-logo {
      width: 300px;
      max-width: 100%;
      height: 35px;
      background: url("../../assets/images/logo.png") no-repeat center;
      background-size: contain;
      margin: 0 auto;
    }

    .login-form {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .login-title {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 13px;
      }

      .ant-alert {
        margin-bottom: 15px;
      }

      .login-btn {
        width: 215px;
        max-width: 100%;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        border-radius: 5px;

        &:hover,
        &:focus {
          opacity: 0.9;
        }
      }
    }
  }
}
