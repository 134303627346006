.app-layout {
  height: 100vh;
  overflow: hidden;
  background: var(--white);

  .app-container {
    width: 100%;
    max-width: 1140px;
    padding-right: calc(3rem * 0.5);
    padding-left: calc(3rem * 0.5);
    margin: 0 auto;
    clear: both;

    @media (max-width: 991px) {
      max-width: 960px;
    }

    @media (max-width: 767px) {
      max-width: 720px;
    }

    @media (max-width: 575px) {
      max-width: 540px;
    }
  }

  .app-footer {
    text-align: center;
    display: none;
  }

  .app-header {
    background: var(--white);
    border-bottom: 0.5px solid var(--black);
    height: 115px;
    clear: both;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
    padding-inline: 0;

    .app-container {
      height: 100%;
      padding-top: 25px;
    }

    .app-logo {
      height: 32px;
      width: 269px;
      background-image: url("../../assets/images/logo-dark.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      float: left;
      margin: 16px 24px 16px 0;
    }

    .app-menu {
      border-bottom: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: calc(100% - 25px);

      &__item {
        text-transform: uppercase;
        font-weight: 300;
        font-family: var(--secondary-font-family);
        font-size: 15px;
        line-height: 40px;

        &::after {
          border-width: 1px;
        }

        &:hover {
          &::after {
            border-width: 1px;
            border-bottom-color: var(--primary-color);
          }
        }

        .ant-menu-title-content a {
          .count {
            background: #ed3f25;
            border-radius: 50%;
            color: #fff;
            width: 18px;
            height: 18px;
            display: inline-flex;
            line-height: 18px;
            justify-content: center;
            margin-left: 4px;
            font-size: 13px;
          }
        }

        &__logout {
          // display: none;
          &:hover {
            &::after {
              border-bottom: none;
            }
          }
        }
      }
    }

    &.has-switch-container {
      height: 165px;

      .app-container {
        padding-top: 20px;
      }

      .app-menu {
        height: calc(100% - 86px);
      }

      .switch-client-container {
        background: var(--primary-color);
        box-shadow: 0px 4px 4px rgba(162, 28, 8, 0.25);
        padding: 16px;

        .switch-client-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          label {
            margin-right: 5px;
            color: #fff;
            line-height: initial;
          }
        }
      }
    }

    .ant-menu-item-selected {
      color: var(--primary-color);

      &::after {
        border-bottom-color: var(--primary-color);
      }

      &:hover {
        &::after {
          border-bottom-color: var(--primary-color);
        }
      }

      &.app-menu__item__logout::after {
        border-bottom: none;
      }
    }

    @media (max-width: 992px) {
      height: 80px;

      .app-logo {
        height: 25px;
        width: 210px;
        margin-top: 4px;
        margin-bottom: 0;
      }

      .app-menu {
        .ant-menu-overflow-item-rest {
          height: 40px;
          line-height: 40px;

          &::after {
            border-color: transparent;
          }

          .ant-menu-submenu-title {
            color: var(--black);
          }
        }
      }

      &.has-switch-container {
        .app-logo {
          margin-top: 18px;
        }
      }
    }

    @media (max-width: 435px) {
      &.has-switch-container {
        .switch-client-container {
          padding: 10px;

          .switch-client-wrapper {
            flex-direction: column;

            label {
              height: auto;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .main-content-layout {
    padding: 40px 0;
    height: 100%;
    overflow: auto;
    z-index: 9;
  }
}

.ant-menu-submenu {
  .ant-menu {
    .ant-menu-item {
      &.ant-menu-item-selected {
        color: var(--primary-color);
        background: rgba(#ed3e24, 0.1);
      }
    }
  }
}
