.dream-department-page {
  &__title {
    h1 {
      font-family: var(--default-font-family);
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 13px;
    }

    p {
      font-family: var(--default-font-family);
      font-size: 20px;
      font-weight: 300;
      line-height: 25px;
    }

    h3 {
      font-family: var(--default-font-family);
      font-size: 30px;
      font-weight: 700;
      margin-top: 50px;
      text-align: center;
    }

    &__action {
      display: flex;
      justify-content: center;
      margin-top: 46px;

      .ant-btn {
        padding: 0;
        width: 337px;
        height: 65px;
        font-size: 24px;
        font-weight: 700;
        color: #00ba13;
        background: #fff;
        font-style: normal;
        padding-left: 50px;
        position: relative;
        border-radius: 30px;
        border: 1px solid #2ba50d;

        .ant-btn-icon {
          top: 12px;
          left: 15px;
          font-size: 40px;
          position: absolute;
        }

        &:hover {
          color: #fff !important;
          background: #00ba13 !important;
          border-color: #2ba50d !important;
        }

        &:focus {
          color: #00ba13 !important;
          background: #fff !important;
          border-color: #2ba50d !important;
        }
      }
    }
  }

  &__collapse {
    margin-bottom: 15px;

    .ant-collapse-item {
      margin-bottom: 4px;
      background: var(--white-1);

      .ant-collapse-header {
        position: relative;

        .ant-collapse-header-text {
          color: var(--black);
          font-size: 20px;
          font-weight: 700;
          font-family: var(--secondary-font-family);
          line-height: 34px;
          padding-left: 8px;

          .anticon {
            height: 26px;
            width: 26px;
            position: absolute;
            left: -12px;
            top: 16px;

            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;

            &.warning {
              background: var(--warning-color);
            }

            &.failed {
              color: var(--white);
              background: var(--primary-color);
            }

            &.success {
              color: var(--white);
              background: var(--success-color);
            }
          }
        }

        .ant-collapse-extra {
          font-family: var(--secondary-font-family);

          &__actions-container {
            span {
              margin-right: 17px;
            }

            .ant-btn {
              width: 197px;
              height: 34px;
              line-height: normal;
              font-size: 13px;
              font-weight: 700;
              border-radius: 20px;
              border: 1px solid var(--yellow);
              background: #ffedae;
              text-align: center;
              position: relative;

              &:hover {
                color: inherit !important;
                background: #f8e291 !important;
                border-color: var(--yellow) !important;
              }

              &:focus {
                color: inherit !important;
                background: #ffedae !important;
                border-color: var(--yellow) !important;
              }

              & > span:not(.ant-btn-icon) {
                margin: 0;
              }

              &.btn-pick {
                background: #ffd027;

                .ant-btn-icon {
                  position: absolute;
                  left: 12px;
                  top: 9px;
                }

                & > span:not(.ant-btn-icon) {
                  margin-left: 9px;
                }

                &:hover {
                  background: #f0c117 !important;
                  border-color: var(--yellow) !important;
                }
              }

              &.btn-view {
                background: var(--white-7);
                border-color: var(--black-7);

                &:hover {
                  background: #b0aeae !important;
                  border-color: var(--black-7) !important;
                }

                &:focus {
                  background: var(--white-7) !important;
                  border-color: var(--black-7) !important;
                }
              }

              &.btn-need-approve {
                background: var(--primary-color);
                border-color: var(--primary-color);
                color: var(--white);

                &:hover {
                  color: var(--white) !important;
                  background: #c0230b !important;
                  border-color: var(--failed-color) !important;
                }
              }

              &.ant-btn-icon-only {
                min-width: auto;
                height: 35px;
                width: 35px;
                border-radius: 50px;
                font-size: 20px;

                .ant-btn-icon {
                  position: relative;
                  top: auto;
                  left: auto;
                }
              }
            }
          }
        }
      }

      &:last-child {
        border-radius: 0;

        & > .ant-collapse-header {
          border-radius: 0;
        }
      }

      &.ant-collapse-item-active {
        background: #d9d9d9;
      }

      &.status-approved {
        background: #fff;
        border: 1px solid #7a7a7a;

        .ant-collapse-extra {
          &__actions-container {
            .status-text {
              color: var(--success-color);
            }
          }
        }
      }
    }

    &__content {
      margin-bottom: 10px;

      &__title {
        padding-bottom: 36px;

        h4 {
          text-align: center;
          font-size: 24px;
          font-weight: 400;
          width: 816px;
          margin: 0 auto;

          &.needs-approval-text {
            color: var(--failed-color);
            font-weight: 700;
          }
        }

        &.has-actions {
          margin-bottom: 36px;
          border-bottom: 1px dashed var(--black);
        }

        &__actions {
          display: flex;
          justify-content: center;
          margin-top: 10px;

          .ant-btn {
            width: 180px;
            height: 26px;
            font-size: 14px;
            font-weight: 400;
            color: var(--black-3);
            background-color: var(--white);
            border-color: var(--white-4);
            margin: 0 6px;
            padding: 0;
            position: relative;

            .ant-btn-icon {
              font-size: 12px;
              position: absolute;
              left: 7px;
              top: 6px;
            }

            &.btn-approve {
              padding-left: 10px;

              .ant-btn-icon {
                color: var(--success-color);
              }
            }

            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }

      &__form {
        .ant-input {
          z-index: 8;
          background: var(--white);
          border-color: var(-black-6);
        }

        .ant-btn {
          z-index: 9;
          margin-left: -5px;
          color: var(--white);
          background: var(--black-5);
          border-end-start-radius: 6px !important;
          border-start-start-radius: 6px !important;
        }
      }

      &__nodes {
        &__fetching,
        &__empty {
          display: block;
          margin: 30px 0;
          margin-left: 10px;
          color: #7e7e7e;
          font-size: 20px;
        }

        &__empty {
          font-style: italic;
        }
      }
    }
  }
}

.dream-department-page-modal {
  .ant-modal-content {
    .ant-modal-close {
      width: 25px;
      height: 25px;
      background: var(--failed-color);
      color: var(--white);
      border-radius: 50%;
      top: 30px;
      inset-inline-end: 30px;
    }

    .ant-modal-title {
      text-align: center;
      margin: 0 auto;
      margin-bottom: 27px;
      margin-top: 20px;

      h2 {
        font-family: var(--default-font-family);
        font-size: 32px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }

      h5 {
        font-family: var(--default-font-family);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 0 auto;
        margin-top: 15px;

        &.danger-text {
          color: var(--failed-color);

          span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.pick-template-modal {
  max-width: 90%;

  .ant-modal-content {
    .ant-modal-title {
      width: 900px;
      max-width: 90%;
    }
  }

  &__skip {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    .ant-btn {
      width: 205px;

      &:hover {
        color: inherit;
      }
    }
  }
}

.apply-template-modal {
  .ant-modal-content {
    .ant-modal-title {
      width: 722px;

      h5 {
        font-size: 20px;
        line-height: normal;
        font-weight: 300;
      }
    }
  }
}

.approve-department-modal {
  .ant-modal-content {
    .ant-modal-title {
      margin-top: 70px;
    }

    .ant-modal-body {
      margin: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-btn {
        width: 235px;
        height: 38px;
        margin-bottom: 20px;
        border-radius: 30px;
        background: var(--white);
        color: var(--success-color);
        border: 1px solid var(--success-color);

        &:hover {
          color: var(--white) !important;
          background-color: var(--success-color) !important;
          border-color: var(--success-color) !important;
        }
      }

      & > span {
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        text-decoration-line: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.change-department-type-modal {
  .ant-modal-content {
    .ant-modal-title {
      margin-top: 70px;

      h5 {
        font-size: 20px;
        line-height: normal;
        margin-top: 25px;
        font-weight: 300;
      }
    }

    .ant-modal-body {
      margin: 60px 0;

      .ant-form {
        &.ant-form-inline {
          justify-content: center;
          align-items: center;
        }

        .ant-btn {
          width: 120px;
          height: 39px;
          background-color: var(--black-5);
          border-color: var(--black-5);
        }

        .ant-select-single {
          height: 39px;
        }
      }
    }
  }
}

.initial-department-modal {
  .ant-modal-content {
    .ant-modal-title {
      margin-top: 70px;
    }

    .ant-modal-body {
      margin: 60px 0;

      .ant-form {
        &.ant-form-inline {
          justify-content: center;
          align-items: center;
        }

        .ant-btn {
          width: 120px;
          height: 39px;
          background-color: var(--black-5);
          border-color: var(--black-5);
        }

        .ant-select-single {
          height: 39px;
        }
      }
    }
  }
}

.select-node-modal {
  .ant-modal-content {
    .ant-modal-title {
      width: 900px;
      max-width: 90%;

      h5 {
        font-size: 20px;
        margin-top: 40px;
      }
    }

    .ant-modal-body {
      overflow: hidden;
      overflow-y: auto;
      margin-top: 50px;
      margin-bottom: 10px;
      max-height: calc(100vh - 485px);
    }
  }

  &__nodes {
    width: 100%;
    margin-bottom: 0;
    list-style: none;
    padding-inline-start: 0;
    border-bottom: 1px dashed var(--white-5);

    &__item {
      display: flex;
      padding: 8px 7px;
      align-items: center;
      justify-content: space-between;
      border-top: 1px dashed var(--white-5);

      &__name {
        font-size: 14px;

        & > span {
          width: 16px;
          margin-right: 7px;
          display: inline-block;

          img {
            width: 16px;
          }
        }
      }

      .ant-btn {
        padding: 0;
        width: 134px;
        height: 22px;
        font-size: 12px;
        font-weight: 700;
        padding-left: 11px;
        position: relative;
        color: var(--white);
        border-radius: 30px;
        background: #34aef3;
        border: 1px solid #025888;

        .ant-btn-icon {
          top: 4px;
          left: 11px;
          font-size: 12px;
          position: absolute;
        }

        &:hover {
          color: var(--white) !important;
          background: #1c96de !important;
        }

        &:focus {
          color: var(--white) !important;
          background: #34aef3 !important;
          border: 1px solid #025888 !important;
        }

        &:disabled {
          opacity: 0.2;
          pointer-events: none;
        }
      }

      &.has-child {
        padding: 0;
        border-bottom: 0;
        padding-left: 26px;
      }
    }

    &.child-nodes {
      margin: 0;
      border: none;

      .select-node-modal__nodes__item:first-child {
        border-top: none;
      }
    }
  }
}

.dream-template-section {
  width: 100%;
  border: 1px solid var(--white-5);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;

  &__template {
    width: 420px;
    padding: 30px;
    height: 100%;
    display: inline-block;
    border-right: 1px dashed var(--white-5);

    &:last-child {
      border-right: none;
    }

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      margin-bottom: 15px;

      h1 {
        font-family: var(--default-font-family);
        font-size: 24px;
        font-weight: 400;
        margin: 0;
        margin-top: 25px;
        line-height: 30px;

        span {
          display: flex;
          font-weight: 700;
        }

        small {
          color: #747474;
          font-size: 10px;
          font-style: italic;
          font-weight: 400;
          display: block;
          line-height: 17px;
        }
      }

      .ant-btn-default {
        width: 190px;
        border-radius: 30px;
        border: 1px solid var(--blue);
        background: #34aef3;
        color: var(--white);
        height: 38px;
        font-family: var(--default-font-family);
        font-size: 14px;
        font-weight: 700;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;

        .ant-btn-icon {
          font-size: 20px;
          position: absolute;
          left: 12px;
          top: 8px;
        }

        & > span:not(.ant-btn-icon) {
          display: inline-block;
        }

        &:hover,
        &:focus,
        &:active {
          border-color: var(--blue) !important;
          background: #0988d2 !important;
          color: var(--white) !important;
        }

        &.btn-approved {
          background: var(--success-color);
          border-color: var(--success-color);
          cursor: default;

          &:hover,
          &:focus,
          &:active {
            border-color: var(--success-color) !important;
            background: var(--success-color) !important;
            color: var(--white) !important;
          }
        }
      }
    }

    &__nodes {
      height: calc(100% - 155px);
      overflow: auto;
    }
  }
}
