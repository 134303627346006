.request-consent-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .ant-result {
    max-width: 500px;
    margin: 0 auto;

    &.ant-result-error {
      .ant-result-title {
        color: #f5222d;
        text-transform: uppercase;
      }
    }

    &.ant-result-loading {
      .ant-result-icon {

      }
    }
  }
}
