.teams-sharepoint-details-section {
  margin-top: 72px;
  margin-bottom: 70px;

  .app-section-title {
    margin-bottom: -0;

    small {
      font-family: var(--default-font-family);
      font-weight: var(--font-normal);
      font-size: 8px;
      line-height: 10px;
      text-align: center;
      color: var(--black-4);
      display: inline-block;
      padding-left: 13px;
    }
  }

  .teams-sharepoint-details-item {
    margin-top: 35px;

    &__title {
      font-family: var(--default-font-family);
      font-weight: var(--font-normal);
      font-size: 13px;
      line-height: 16px;
      color: var(--primary-color);
      margin-bottom: 33px;
    }

    &__table {
      border: none;
      width: 100%;

      th,
      td {
        font-size: 13px;
        line-height: 16px;
        padding: 0;
        padding-right: 10px;

        &:last-child {
          padding-right: 0;
        }
      }

      thead {
        th {
          text-align: left;
          vertical-align: baseline;
          height: 35px;
        }
      }

      tbody {
        td {
          height: 30px;
          font-weight: var(--font-normal);
        }
      }

      .td-files-qty {
        width: 110px;
      }

      .td-updated-at {
        width: 110px;
      }

      @media (max-width: 576px) {
        .td-files-qty {
          width: 74px;
        }

        .td-updated-at {
          width: 105px;
        }
      }
    }

    &__channels {
      td {
        color: var(--black-6);

        &:first-child {
          padding-left: 15px;
        }
      }
    }
  }
}
